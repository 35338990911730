.info-img {
	width: 16px;
	height: 16px;
	padding-bottom: 2px;
	cursor: pointer;
}

.dropdown-triangle {
	width: 16px;
	height: 16px;
	cursor: pointer;
}



.text-or-num-input {
	width: 400px;
	display: inline-block;
}



.property-group {
	margin-bottom: 10px;
}

.verify-btn {
	margin-left: 10px;
	text-transform: uppercase;
	font-size: 12px;
	background-color: #155671;
	border: 1px solid #155671;
	height: 35;
	width: 100;
}

.verify-btn:hover {
	background-color: white;
	border: 1px solid #006666;
	color: black;
}

.verified-btn {
	margin-left: 10px;
	text-transform: uppercase;
	font-size: 12px;
	background-color: #8CB095;
	border: 1px solid #8CB095;
	height: 35;
	width: 100;
}

.verified-btn:hover {
	background-color: white;
	border: 1px solid #006666;
	color: black;
}

.property-group-input-container {
	margin-bottom: 30px;
}

.property-group-input {
	margin-top: 15px;
}

.property-group-inputs {
	margin-top: 10px;
	margin-left: 15px;
}

.save-btn {
	width: 200px;
}
