.table-view-btn {
	text-transform: uppercase;
	font-size: 12px;
	background-color: #155671;
	border: 1px solid #155671;
	height: 35;
	width: 100;
}

.table-view-btn:hover {
	background-color: white;
	border: 1px solid #006666;
	color: black;
}

/*
#update-properties-table {
	margin-left: 50px;
	width: 1100px;
	min-width: 1100px;
	//table-layout: fixed;
}

#update-properties-table > tr {
	width: 1100px;
	min-width: 1100px;
}

#table-search-input {
	margin-left: 20px;
	width: 835px;
	display: inline;
}*/

#new-property-id {/*
	margin-bottom: 4px;
	margin-left: 50px;*/
	display: inline;
	text-transform: uppercase;
	background-color: #155671;
	border: 1px solid #155671;
	font-size: 12px;
	width: 150px;
}

#new-property-id:hover {
	background-color: white;
	border: 1px solid #006666;
	color: black;
}

#table-search-select {
	margin-left: 20px;
	margin-bottom: 4px;
}

.table-flag {
	font-size: 10px;
	width: 120px;
	padding: 0px;
	padding-top: 3px;
	padding-bottom: 3px;
}

.table-flag-verified {
	background-color: #609f60;
	border-color: #609f60;
}
.table-flag-verified:hover {
	background-color: #609f60;
	border-color: #609f60;
	cursor: default !important;
}

.table-flag-unverified {
	background-color: #de5454;
	border-color: #de5454;
}
.table-flag-unverified:hover {
	background-color: #de5454;
	border-color: #de5454;
	cursor: default !important;
}

.affordability-info-flag {
	margin-top: 5px;
}
