#login_failed_msg, #login_error_msg {
	display: none;
	color: red;
}

.login_input {
	width: 300px;
}

.btn-login {
	background-color: #155671;
	border: 1px solid #155671;
	width: 300px;
}

.btn-login:hover {
	background-color: white;
	border: 1px solid #006666;
	color: #155671;
}

.login-form-container {
	margin-left: 50px;
}

#affordable-housing-data-hub-title {
	font-size: 40px;
	margin-top: 20px;
	margin-left: 50px;
}

#login-title-container {
	display: flex;
	justify-content: center;
}

.login-form-container {
	display: flex;
	justify-content: center;
}

#login-img-container {
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

#login-img-container > img {
	width: 300px;
	height: 300px;
}